import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, ContentCopy, DirectionsCar, Email } from '@mui/icons-material';
import { Button, List, ListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import StyledWrapper from './StyledHeaderWrapper';
import LicensePlate from 'app/shared/components/LicensePlate';
import Loader from 'app/shared/components/Loader';
import Row from 'app/shared/components/Row';

import { useSelector } from 'hooks/global';

//import { useAppTranslation } from 'hooks/useAppTranslation';
import { selectRequestDetails } from 'store/selectors/serviceRequests.selector';

import { VIN_NUMBER_LIMIT } from 'constants/general';

import { limitStrWithPad } from 'utilities';
import copyToClipboard from 'utilities/copyToClipboard';

interface RequestDetailsHeaderProps {
  isLoading?: boolean;
}

const RequestDetailsHeader = ({ isLoading }: RequestDetailsHeaderProps) => {
  const { t } = useTranslation();

  const { vehicle, address, invoiceAccount } =
    useSelector(selectRequestDetails);

  const { licensePlate, mark, model, type, vin, odometerValue, odometerUnit } =
    vehicle;

  const vehicleLogo = <DirectionsCar />;

  const restrictedVin = useMemo(
    () =>
      limitStrWithPad(vin, {
        limit: VIN_NUMBER_LIMIT,
      }),
    [vin]
  );

  const handleCopyToClipboard = useCallback(() => copyToClipboard(vin), [vin]);

  return (
    <StyledWrapper variant='space-between'>
      <Row className='section' variant='space-between'>
        <Row className='vehicle-plate' variant='space-around'>
          <Row className='logo-and-plate'>
            <Box className='car-logo'>{vehicleLogo}</Box>
            <LicensePlate
              countryCode={licensePlate?.countryCode}
              plateId={licensePlate?.number}
            />
          </Row>
          <Typography className='car-brand-label'>
            {mark}
            {` `}
            {model}
            {` `}
            {type}
          </Typography>
          <Row className='vin-number'>
            {isLoading ? (
              <Loader width={30} height={30} />
            ) : (
              <>
                <Typography>
                  <strong>VIN:</strong>
                  {` `}
                  {restrictedVin}
                </Typography>
                <Button variant='outlined' onClick={handleCopyToClipboard}>
                  <ContentCopy />
                </Button>
              </>
            )}
          </Row>
          <Box className='milage'>
            <Button variant='outlined'>{odometerValue}</Button>
            <Button variant='outlined'>{odometerUnit}</Button>
          </Box>
        </Row>
      </Row>
      <Row className='section' variant='space-between'>
        <Box className='address-details'>
          <Typography className='address-details-label'>
            <Badge />
            {t('requestDetailsHeader.address')}
          </Typography>
          <List>
            {isLoading ? (
              <Loader width={30} height={30} />
            ) : (
              <>
                <ListItem disablePadding>
                  <Typography>{address.name}</Typography>
                </ListItem>
                <ListItem disablePadding>
                  <Typography>{address.address}</Typography>
                </ListItem>
                <ListItem disablePadding>
                  <Typography>
                    {address.postalCode}
                    {`, `}
                    {address.city}
                  </Typography>
                </ListItem>
              </>
            )}
          </List>
        </Box>
        <Box className='invoice-details'>
          <Typography className='invoice-details-label'>
            <Email />
            {t('requestDetailsHeader.invoice')}
          </Typography>
          <List>
            {isLoading ? (
              <Loader width={30} height={30} />
            ) : (
              <>
                <ListItem disablePadding>
                  <Typography>{invoiceAccount.name}</Typography>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Row>
    </StyledWrapper>
  );
};

export default RequestDetailsHeader;
