import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  CHINESE_WHITE,
  FLASH_WHITE,
  STEEL_BLUE,
  WHITE,
} from 'constants/colors';

import { dashboardColorStyle } from 'utilities/workOrderLineColors';

const ServiceRequestsContainer = styled(Container)(() => ({
  '& .header-wrapper': {
    alignItems: 'center',
    marginBottom: '24px',
  },

  '& .history-header': {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },

  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${CHINESE_WHITE}`,
    display: 'flex',
    alignItems: 'center',
  },

  '& .data-grid-wrapper': {
    '& .MuiDataGrid-root': {
      backgroundColor: WHITE,

      'div:has(.no-data-element)': {
        height: 'auto !important',
      },

      '.no-data-element': {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '.MuiDataGrid-row': {
        cursor: 'pointer',
      },

      '.data-grid-custom-footer': {
        alignItems: 'center',
        borderTop: `1px solid ${CHINESE_WHITE}`,

        '.MuiButton-root': {
          textTransform: 'none',
          padding: 0,

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },

        '.reset-filtering': {
          display: 'flex',
          color: STEEL_BLUE,

          svg: {
            marginRight: 8,
            marginLeft: 14,
          },
        },
      },
    },

    '.MuiDataGrid-cellContent': {
      whiteSpace: 'initial',
    },

    '.MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'initial',
      lineHeight: 'normal',
    },

    '& .flex-center--cell': {
      justifyContent: 'center',
      padding: 0,
    },

    '& .request-status--cell': {
      padding: '0 8px',
      lineHeight: 'normal',

      div: {
        width: '100%',
        padding: '8px 12px',
        borderRadius: '50px',
        textAlign: 'center',
        backgroundColor: FLASH_WHITE,
        textTransform: 'capitalize',
      },
    },

    ...dashboardColorStyle(),
  },
}));

export default ServiceRequestsContainer;
