//import { useAppTranslation } from 'hooks/useAppTranslation';
import { useTranslation } from 'react-i18next';

import PopperCell from 'app/shared/components/PopperCell';

const TimeCell = ({
  time,
  agreedTime,
}: {
  time: string | null;
  agreedTime: string | null;
}) => {
  const { t } = useTranslation();

  const popperLabel = `${t('offerteGegevens.originalValue')}: ${
    agreedTime || '0'
  }`;

  return <PopperCell label={popperLabel} value={time} />;
};

export default TimeCell;
