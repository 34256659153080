import { ReactNode } from 'react';
import Path from 'routes/path';

import { AppState, Auth0Provider } from '@auth0/auth0-react';

const DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || '';
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || '';
const AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;

interface Props {
  children: ReactNode;
}

const Auth = ({ children }: Props) => {
  const onRedirectCallback = (appState?: AppState) => {
    let returnToUrl = window.location.origin + Path.dashboard;

    if (appState && appState.returnTo) {
      returnToUrl = appState.returnTo;
    }

    window.history.replaceState({}, '', returnToUrl);
  };

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirectUri: window.location.origin + Path.dashboard,
        audience: AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth;
