import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOption } from 'types/global';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';

//import { useAppTranslation } from 'hooks/useAppTranslation';
import { StatusProps } from 'constants/requestDetails';

const FilterStatusInputField = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();

  const getOptionLabel = useCallback(
    (label?: string | number) =>
      label
        ? t(`dashboard.requestStatuses.${StatusProps[Number(label) as keyof typeof StatusProps]?.key}`)
        : '',
    [t]
  );

  const value: DropdownOption = {
    label: getOptionLabel(item?.value),
    id: (item?.value as string) || '',
  };

  const options: DropdownOption[] = useMemo(
    () =>
      Object.keys(StatusProps).map(key => ({
        label: getOptionLabel(key),
        id: key,
      })),
    [getOptionLabel]
  );

  const handleFilterChange = useCallback(
    (
      __: SyntheticEvent<Element, Event>,
      newValue: string | DropdownOption | null
    ) => {
      const isString = typeof newValue === 'string';

      return applyValue({ ...item, value: isString ? newValue : newValue?.id });
    },
    [applyValue, item]
  );

  return (
    <Autocomplete
      freeSolo
      forcePopupIcon
      options={options}
      value={value}
      onChange={handleFilterChange}
      renderInput={params => (
        <TextField
          {...params}
          label='Value'
          placeholder='Filter value'
          variant='standard'
        />
      )}
    />
  );
};

export default FilterStatusInputField;
