import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import Path, { ROUTING_PARAMS } from 'routes/path';

import BackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import StyledWrapperRow from './ActionsRowStyledWrapper';
import Row from 'app/shared/components/Row';

import { useSelector } from '../../../../../hooks/global';
import { useDispatch } from 'hooks/global';
//import { useAppTranslation } from 'hooks/useAppTranslation';
import useHistoryPath from 'hooks/useHistoryPath';

import { selectRequestId } from '../../../../../store/selectors/serviceRequests.selector';
import { resetRequestDetails } from 'store/slices/requestDetails.slice';

import { TestId } from 'constants/testIds';

import { formatDate } from 'utilities/dateUtils';

interface ActionsRowProps {
  requestNum: string;
  invoiceNumber: string;
  serviceRequestId: string;
  startDate: string;
  invoiceDate: string;
  externalReference: string;
}

const getButtonVariant = (pattern: string, pathName: string) =>
  matchPath(pattern, pathName) ? 'active-button' : 'inactive-button';

const ActionsRow = ({
  requestNum,
  invoiceNumber,
  serviceRequestId,
  startDate,
  invoiceDate,
  externalReference,
}: ActionsRowProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isHistoryPath } = useHistoryPath();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requestId = useSelector(selectRequestId);

  const pathStr = isHistoryPath ? 'historyRequestDetails' : 'requestDetails';

  const handleOpenRequestDetails = useCallback(
    (pathName: string) => {
      const path = Path.generate(
        Path[pathStr][pathName as keyof typeof Path.requestDetails],
        {
          [ROUTING_PARAMS.serviceRequestId]: serviceRequestId,
        }
      );

      navigate(path, { replace: false, state: { name: path } });
    },
    [navigate, pathStr, serviceRequestId]
  );

  const handleNavigateBack = useCallback(() => {
    dispatch(resetRequestDetails());
    const path = isHistoryPath ? Path.history : Path.dashboard;
    navigate(path, { replace: false, state: { name: path } });
  }, [dispatch, isHistoryPath, navigate]);

  const buttons = useMemo(
    () => [
      {
        label: t('requestDetailsSection.navButtons.total'),
        className: getButtonVariant(Path[pathStr].total, pathname),
        testId: TestId.ROB_TOTALS_NAV_BTN,
        onClick: () => handleOpenRequestDetails('total'),
      },
      {
        label: t('requestDetailsSection.navButtons.workCard'),
        className: getButtonVariant(Path[pathStr].workCard, pathname),
        testId: TestId.ROB_WERKKAART_NAV_BTN,
        onClick: () => handleOpenRequestDetails('workCard'),
      },
      {
        label: t('requestDetailsSection.navButtons.details'),
        className: getButtonVariant(Path[pathStr].details, pathname),
        testId: TestId.ROB_OFFERTE_GEGEVENS_NAV_BTN,
        onClick: () => handleOpenRequestDetails('details'),
      },
    ],
    [handleOpenRequestDetails, pathStr, pathname, t]
  );

  return (
    <StyledWrapperRow variant='space-between'>
      <Row className='first-half'>
        <Button
          id='back-button'
          disableElevation
          disableRipple
          onClick={handleNavigateBack}
          variant='text'
        >
          <BackIcon />
        </Button>
        <Typography>{`${t(
          'requestDetailsSection.externalReference'
        )} ${externalReference}`}</Typography>
        {isHistoryPath ? (
          <>
            <Typography>{`${t('requestDetailsSection.invoiceLabel')} ${invoiceNumber}`}</Typography>
            <TextField
              disabled
              className='date-picker invoice-date'
              label={t('requestDetailsSection.invoiceDate')}
              value={formatDate(invoiceDate)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <>
            <Typography>{`${t('requestDetailsSection.request')} ${requestNum}`}</Typography>
            <TextField
              disabled
              className='date-picker'
              label={t('requestDetailsSection.startDate')}
              value={formatDate(startDate)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Row>
      <Row>
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          {buttons.map(({ className, testId, label, onClick }) => (
            <Button
              key={label}
              size='small'
              data-testid={testId}
              disabled={!requestId}
              className={className}
              onClick={onClick}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      </Row>
    </StyledWrapperRow>
  );
};

export default ActionsRow;
