import { useEffect } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import Dashboard from 'app/components/Dashboard';
import ErrorFallback from 'app/components/ErrorFallback';
import withAuth from 'app/hocs/withAuth';

import { useDispatch, useSelector } from 'hooks/global';

import { selectCurrentLocaleId } from './store/selectors/userSettings.selector';
import { setUser } from './store/slices/userSettings.slice';

import withSentryErrorBoundary, { SentryHelper } from './config/sentry';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
SentryHelper.init(SENTRY_DSN);

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { isAuthenticated, user } = useAuth0();

  const curLocale = useSelector(selectCurrentLocaleId);

  useEffect(() => {
    i18n.changeLanguage(curLocale);
  }, [curLocale, i18n]);

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(setUser(user));
    }
  }, [dispatch, isAuthenticated, user]);

  return (
    <Dashboard>
      <Outlet />
    </Dashboard>
  );
};

export default withErrorBoundary(
  withSentryErrorBoundary(withAuth(App), {
    fallback: ErrorFallback,
  }),
  {
    FallbackComponent: ErrorFallback,
    onError: console.error,
  }
);
