import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCellContentValue } from 'app/components/RobWerkkaart/utils';
import RequestDetails from 'app/shared/components/RequestDetails';

import { useSelector } from '../../../hooks/global';

//import { useAppTranslation } from 'hooks/useAppTranslation';
import { selectRequestDetails } from '../../../store/selectors/serviceRequests.selector';

import { getTotalValues } from './utils';

const tableConfig = {
  status: { align: undefined },
  type: { align: 'center' },
  articleNumber: { align: 'left' },
  description: { align: 'left' },
  quantity: { align: 'right' },
  totalCost: { align: 'right' },
  robCode: { align: undefined },
  activityCode: { align: undefined },
  damageCode: { align: undefined },
  location: { align: undefined },
  warranty: { align: undefined },
};

const RobWerkkaart = () => {
  const { t } = useTranslation();

  const { totals } = useSelector(selectRequestDetails);

  const formattedTotalValues = useMemo(() => getTotalValues(totals), [totals]);

  return (
    <RequestDetails
      t={t}
      totalValues={formattedTotalValues}
      tableConfig={tableConfig}
      getCellContentValue={getCellContentValue}
    />
  );
};

export default RobWerkkaart;
