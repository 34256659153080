import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import StyledWrapperRow from './StyledWrapperRow';
import Row from 'app/shared/components/Row';

import { useSelector } from '../../../../../../hooks/global';

//import { useAppTranslation } from 'hooks/useAppTranslation';
import { selectRequestVatDetails } from 'store/selectors/serviceRequests.selector';

import formatValueToCurrency from 'utilities/formatValueToCurrency';

import VatTotalItemsBox from './VatTotalItemsBox';

interface TotalAmountRowProps {
  incVatAmount: string | number;
  exVatAmount: string | number;
  location?: string;
}

export const EX_VAT_ID = 'excluding-vat-amount';
export const INC_VAT_ID = 'including-vat-amount';

const TotalAmountRow = ({
  incVatAmount,
  exVatAmount,
  location,
}: TotalAmountRowProps) => {
  const { t } = useTranslation();
  const [exVatAnchorEl, setExVatAnchorEl] = useState<null | HTMLElement>(null);
  const [inclVatAnchorEl, setInclVatAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [curActive, setCurActive] = useState<null | string>(null);

  const vatDetails = useSelector(selectRequestVatDetails);

  const incAmount = formatValueToCurrency(incVatAmount);
  const exAmount = formatValueToCurrency(exVatAmount);

  const isBottomLocation = location === 'bottom';

  const handleExVatButtonClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      if (isBottomLocation) {
        return null;
      }
      setCurActive(currentTarget.id);

      setInclVatAnchorEl(null);
      setExVatAnchorEl(exVatAnchorEl ? null : currentTarget);
    },
    [exVatAnchorEl, isBottomLocation]
  );

  const handleInclVatButtonClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      if (isBottomLocation) {
        return null;
      }
      setCurActive(currentTarget.id);
      setExVatAnchorEl(null);
      setInclVatAnchorEl(inclVatAnchorEl ? null : currentTarget);
    },
    [inclVatAnchorEl, isBottomLocation]
  );

  const handleClose = () => {
    setExVatAnchorEl(null);
    setInclVatAnchorEl(null);
    setCurActive(null);
  };

  const vatButtons = useMemo(
    () => [
      {
        vatId: EX_VAT_ID,
        value: `${exAmount} ${t('requestDetailsSection.vatButtons.excludingVat')}`,
        onClick: handleExVatButtonClick,
      },
      {
        vatId: INC_VAT_ID,
        value: `${incAmount} ${t('requestDetailsSection.vatButtons.includingVat')}`,
        onClick: handleInclVatButtonClick,
      },
    ],
    [exAmount, handleExVatButtonClick, handleInclVatButtonClick, incAmount, t]
  );

  return (
    <StyledWrapperRow location={location} variant='flex-end'>
      {vatButtons.map(({ vatId, value, onClick }) => {
        const anchorEl = vatId === EX_VAT_ID ? exVatAnchorEl : inclVatAnchorEl;

        return (
          <Row key={vatId}>
            <Button
              disableElevation
              disableRipple
              id={vatId}
              aria-describedby={vatId}
              type='button'
              onClick={onClick}
            >
              {value}
            </Button>
            {!isBottomLocation && (
              <VatTotalItemsBox
                anchorEl={anchorEl}
                handleClose={handleClose}
                vatId={vatId}
                vatDetails={vatDetails}
                curActive={curActive}
              />
            )}
          </Row>
        );
      })}
    </StyledWrapperRow>
  );
};

export default TotalAmountRow;
