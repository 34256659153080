import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RequestDetails from 'app/shared/components/RequestDetails';

import { useSelector } from 'hooks/global';

//import { useAppTranslation } from 'hooks/useAppTranslation';
import { selectRequestDetails } from 'store/selectors/serviceRequests.selector';

import { formatTotalValues, getCellContentValue } from './utils';

const tableConfig = {
  robCode: { align: undefined },
  description: { align: undefined },
  damageCode: { align: 'right' },
  activityCode: { align: 'right' },
  location: { align: undefined },
  warranty: { align: undefined },
  costs: { align: 'right' },
  totalCost: { align: 'right' },
  status: { align: 'center' },
};

const RobTotalen = () => {
  const { t } = useTranslation();

  const { totals } = useSelector(selectRequestDetails);
  const formattedTotalValues = useMemo(
    () => formatTotalValues(totals),
    [totals]
  );

  return (
    <RequestDetails
      t={t}
      isTotalDetails
      totalValues={formattedTotalValues}
      tableConfig={tableConfig}
      getCellContentValue={getCellContentValue}
    />
  );
};

export default RobTotalen;
