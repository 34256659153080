import {
  GridCellParams,
  GridFilterItem,
  GridFilterModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { enUS, nlNL } from '@mui/x-data-grid-pro/locales';

import {
  LocaleId,
  NextLink,
  OperatorTypes,
  Order,
} from '../../../constants/general';

export const getCellClassName = ({ field, value }: GridCellParams) => {
  const isStatusField = field === 'status';

  if (isStatusField) {
    return `request-status--${value}--cell`;
  }

  return '';
};

export const getFilterParams = (model?: GridFilterModel | null) => {
  const { items, logicOperator } = model || {};
  return {
    queryBlockSearches: items?.map((item: GridFilterItem, idx: number) => {
      const lastItem = items?.length - 1 === idx;
      return {
        fieldName: item.field,
        operatorTypes:
          OperatorTypes[item?.operator as keyof typeof OperatorTypes],
        searchString: item.value as string,
        ...(!lastItem
          ? {
              nextLink: NextLink[logicOperator as keyof typeof NextLink],
            }
          : {}),
      };
    }),
  };
};

export const getSortParams = (items?: GridSortModel | null) => {
  return {
    orderByList: items?.map((item: GridSortItem) => ({
      orderBy: item.field,
      order: Order[item.sort as keyof typeof Order],
    })),
  };
};

export const getDataGridLocale = (loc?: string) => {
  const locales = {
    [LocaleId.EN]: enUS,
    [LocaleId.NL]: nlNL,
  };

  return locales[loc || LocaleId.EN]?.components?.MuiDataGrid.defaultProps
    .localeText;
};
