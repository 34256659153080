import { TFunction } from 'i18next';
import { VatDetails } from 'types/serviceRequest';
import formatValueToCurrency from 'utilities/formatValueToCurrency';

export interface VatTotalItemProps {
  label: string;
  value: string | null;
}

export function getVatTotalItems(
  t: TFunction<'translation', string>,
  vatDetails: VatDetails
): VatTotalItemProps[] {
  return [
    {
      label: `${t('vatDetails.products')}:`,
      value: formatValueToCurrency(vatDetails.products),
    },
    {
      label: `${t('vatDetails.services')}:`,
      value: formatValueToCurrency(vatDetails.services),
    },
    {
      label: `${t('vatDetails.tyres')}:`,
      value: formatValueToCurrency(vatDetails.tyres),
    },
    {
      label: `${t('vatDetails.discount')}:`,
      value: formatValueToCurrency(vatDetails.discount),
    },
    {
      label: 'divider',
      value: null,
    },
    {
      label: `${t('vatDetails.exVatTotal')}:`,
      value: formatValueToCurrency(vatDetails.exVatTotal),
    },
    {
      label: `${t('vatDetails.vatTotal')}:`,
      value: formatValueToCurrency(vatDetails.vatTotal),
    },
    {
      label: 'divider',
      value: null,
    },
    {
      label: `${t('vatDetails.inclVatTotal')}:`,
      value: formatValueToCurrency(vatDetails.inclVatTotal),
    },
  ];
}
