import { useTranslation } from 'react-i18next';

import PopperCell from 'app/shared/components/PopperCell';

import formatValueToCurrency from 'utilities/formatValueToCurrency';

const BruttoPriceCell = ({
  brutto,
  bruttoVatIncl,
}: {
  brutto: string | number;
  bruttoVatIncl: string | number;
}) => {
  const { t } = useTranslation();

  const popperLabel = `${t(
    'offerteGegevens.originalValue'
  )}: ${formatValueToCurrency(bruttoVatIncl || '0')}`;

  return (
    <PopperCell label={popperLabel} value={formatValueToCurrency(brutto)} />
  );
};

export default BruttoPriceCell;
