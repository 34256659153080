import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import {
  AccountCircle,
  KeyboardArrowDown,
  Language,
  StoreMallDirectory,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { useDispatch, useSelector } from '../../../hooks/global';
import {
  selectCurrentFleet,
  selectCurrentLanguage,
  selectCurrentLocaleId,
  selectLocales,
  selectUserFullName,
} from '../../../store/selectors/userSettings.selector';
import { setCurrentLocaleId } from '../../../store/slices/userSettings.slice';

import { LocaleId } from 'constants/general';
import { TestId } from 'constants/testIds';

import { clearStorage } from 'utilities/storageUtils';

import AppBar from './AppBar';
import DateTimeLabel from './DateTimeLabel';
import ProfileDropdownMenu from './ProfileDropdownMenu';

const dayjsLocales = {
  [LocaleId.EN]: 'en',
  [LocaleId.NL]: 'nl',
};

export const languageMenuId = 'language-menu';
export const userMenuId = 'user-menu';

const Header = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const curLanguage = useSelector(selectCurrentLanguage);
  const curLocale = useSelector(selectCurrentLocaleId);
  const userFleet = useSelector(selectCurrentFleet);
  const userFullName = useSelector(selectUserFullName);
  const locales = useSelector(selectLocales);

  const dropdownMenuId = anchorEl?.dataset?.menuid;
  const locale = dayjsLocales[i18n.language] || 'en';

  const isLanguageMenuOpen = dropdownMenuId === languageMenuId;
  const isUserMenuOpen = dropdownMenuId === userMenuId;

  const options = useMemo(
    () => ({
      [languageMenuId]: locales,
      [userMenuId]: [{ id: 'userLogout', label: 'Log Out' }],
    }),
    [locales]
  );

  const handleMenuOpen = ({ currentTarget }: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleLanguageMenuItemClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      const localeId = currentTarget.dataset?.id || '';

      dispatch(setCurrentLocaleId(localeId));
      setAnchorEl(null);
    },
    [dispatch]
  );

  const handleUserMenuItemClick = () => {
    clearStorage();
    setAnchorEl(null);
    logout();
  };

  return (
    <AppBar>
      <DateTimeLabel locale={locale} />
      <Box className='user-settings-wrapper'>
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          data-testid={TestId.HEADER_LANGUAGE_BUTTON}
          data-menuid={languageMenuId}
          size='medium'
          variant='text'
          onClick={handleMenuOpen}
          startIcon={<Language />}
          endIcon={<KeyboardArrowDown />}
        >
          {curLanguage}
        </Button>
        <Typography
          className='user-fleet'
          data-testid={TestId.HEADER_USER_META}
        >
          <StoreMallDirectory />
          {userFleet}
        </Typography>
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          data-testid={TestId.HEADER_USER_BUTTON}
          data-menuid={userMenuId}
          size='medium'
          variant='text'
          onClick={handleMenuOpen}
          startIcon={<AccountCircle />}
          endIcon={<KeyboardArrowDown />}
        >
          {userFullName}
        </Button>

        {isLanguageMenuOpen && (
          <ProfileDropdownMenu
            isMenuOpen={isLanguageMenuOpen}
            anchorEl={anchorEl}
            menuId={languageMenuId}
            options={options[dropdownMenuId]}
            selectedOption={curLocale}
            onClose={handleMenuClose}
            onClick={handleLanguageMenuItemClick}
          />
        )}

        {isUserMenuOpen && (
          <ProfileDropdownMenu
            isMenuOpen={isUserMenuOpen}
            anchorEl={anchorEl}
            menuId={userMenuId}
            options={options[dropdownMenuId]}
            onClose={handleMenuClose}
            onClick={handleUserMenuItemClick}
          />
        )}
      </Box>
    </AppBar>
  );
};

export default Header;
